const formatToCurrencyBr = (value) => {
  if (!value) return ''
  const money = parseFloat(value)
  return money.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

const formatToNumber = (value) => {
  if (!value) return ''
  const money = parseFloat(value)
  return money.toLocaleString('pt-br')
}

// eslint-disable-next-line import/prefer-default-export
export { formatToCurrencyBr, formatToNumber }

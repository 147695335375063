<template>
  <div>
    <ModalAttachment
      v-if="showModalAttachments"
      :companyCode="companyCode"
      :formStepId="formStepId"
      @closeModal="closeModal"
    ></ModalAttachment>
    <div class="card-body">
      <h5>Orçamento Comercial</h5>
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column">
            <span class="d-flex">
              <span class="bg-success rounded-pill me-2 legend"></span
              >Volume</span
            >
            <span class="d-flex">
              <span class="bg-indigo rounded-pill me-2 legend"></span>Preço
              Médio</span
            >
            <span class="d-flex">
              <span class="bg-pink rounded-pill me-2 legend"></span>Total</span
            >
          </div>
        </div>

        <div class="col">
          <p class="mb-0">
            <strong>Volume Total (Draft):</strong>
            {{ formatToNumber(draft.volume) }}
          </p>
          <p class="mb-0">
            <strong>Volume Total (Orçado):</strong>
            {{ formatToNumber(totalVolume) }}
          </p>
          <p class="mb-0">
            <strong>Diferença:</strong>
            {{ formatToNumber(totalVolume - draft.volume) }}
          </p>
        </div>
        <div class="col">
          <p class="mb-0">
            <strong>Valor Total (Draft):</strong>
            {{ formatToNumber(draft.receitaBruta) }}
          </p>
          <p class="mb-0">
            <strong>Valor Total (Orçado):</strong>
            {{ formatToNumber(totalValue) }}
          </p>
          <p class="mb-0">
            <strong>Diferença:</strong>
            {{ formatToNumber(totalValue - draft.receitaBruta) }}
          </p>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Cliente</th>
            <th v-for="month in 12" :key="month">{{ month }}</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="client in formatedData" :key="client.name">
            <td>{{ client.name }}</td>
            <td v-for="month in 12" :key="month">
              <div
                class="d-flex align-items-center"
                :class="client.months[month].idComercial ? '' : 'opacity-50'"
              >
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    :id="`nr_${removeCharacterSpecials(client.name)}_${month}`"
                    v-model="needsReview"
                    :value="client.months[month].idComercial"
                    :disabled="!client.months[month].idComercial"
                  />
                  <label
                    class="form-check-label"
                    :for="`nr_${removeCharacterSpecials(client.name)}_${month}`"
                  ></label>
                </div>
                <div v-if="client.months[month]">
                  <div class="text-success fw-bold">
                    {{ formatToNumber(client.months[month].volume) }}
                  </div>
                  <div class="text-indigo fw-bold">
                    {{ formatToCurrencyBr(client.months[month].price) }}
                  </div>
                  <div class="text-pink fw-bold">
                    {{ formatToCurrencyBr(client.months[month].total) }}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="text-success fw-bold">
                {{ formatToNumber(client.total.volume) }}
              </div>
              <div class="text-indigo fw-bold">
                {{ formatToCurrencyBr(client.total.price) }}
              </div>
              <div class="text-pink fw-bold">
                {{ formatToCurrencyBr(client.total.total) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="avaliation-container" v-if="status == 0">
      <div class="d-flex justify-content-between">
        <div class="p-3">
          <button
            class="btn btn-secondary ms-2"
            @click="showModalAttachments = true"
          >
            <i class="ph ph-paperclip me-2"></i>
            Visualizar Anexos
          </button>
        </div>
        <div>
          <div class="default-avaliation text-end p-3" v-if="!showReproveForm">
            <button class="btn btn-danger ms-2" @click="showReproveForm = true">
              <i class="ph ph-x-circle me-2"></i>
              Reprovar
            </button>
            <button class="btn btn-success ms-2" @click="approveCommercial()">
              <i class="ph ph-check-circle me-2"></i>
              Aprovar
            </button>
          </div>
          <div class="reprovation-avaliation" v-if="showReproveForm">
            <div class="p-3">
              <p>
                Você pode marcar os apontamentos para atenção do responsável.
              </p>
              <textarea
                class="form-control"
                rows="3"
                placeholder="Justificativa"
                v-model="reproveJustification"
              ></textarea>
              <div class="text-end mt-2">
                <button
                  class="btn btn-secondary ms-2"
                  @click="showReproveForm = false"
                >
                  Cancelar
                </button>
                <button
                  class="btn btn-danger ms-2"
                  @click="reproveCommercial()"
                >
                  <i class="ph ph-x-circle me-2"></i>
                  Reprovar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.legend {
  width: 20px !important;
  height: 20px !important;
}
</style>
<script>
import { formatToCurrencyBr, formatToNumber } from '@/utils/currency'
import ModalAttachment from '@/components/forms/ModalAttachment.vue'

export default {
  name: 'CommercialControllershipEvaluation',
  props: {
    draft: {
      type: Object,
      required: true,
    },
    commercial: {
      type: Array,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
    formStepId: {
      type: Number,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
  },
  components: {
    ModalAttachment,
  },
  data() {
    return {
      formatToCurrencyBr,
      formatToNumber,
      formatedData: [],
      needsReview: [],
      showReproveForm: false,
      reproveJustification: '',
      showModalAttachments: false,
    }
  },
  emits: ['avaliateCommercial'],

  methods: {
    closeModal() {
      this.showModalAttachments = false
    },
    approveCommercial() {
      this.$emit('avaliateCommercial', {
        status: true,
      })
    },
    reproveCommercial() {
      if (this.reproveJustification === '') {
        this.$root.$refs.notification.warning('Justificativa é obrigatória.')
        return false
      }
      this.$emit('avaliateCommercial', {
        needsReview: this.needsReview,
        status: false,
        justification: this.reproveJustification,
      })
    },
    // Remove all characters specials, as spaces, dots, commas, etc
    removeCharacterSpecials(value) {
      return value.replace(/[^0-9]/g, '')
    },
    convertToTable() {
      this.formatedData = this.commercial.reduce((acc, register) => {
        const client = register.NOME
        const month = register.MES
        const volume = Number(register.VOLUME)
        const price = Number(register.PRECOMEDIO)
        const total = Number(register.VALORTOTAL)
        const idComercial = register.IDCOMERCIAL

        if (!acc[client]) {
          acc[client] = {
            name: client,
            months: {},
          }
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= 12; i++) {
            acc[client].months[i] = {
              id: null,
              volume: 0,
              price: 0,
              total: 0,
            }
          }
        }

        if (!acc[client].months[month]) {
          acc[client].months[month] = {
            id: idComercial,
            volume: 0,
            price: 0,
            total: 0,
          }
        }

        acc[client].months[month] = {
          idComercial,
          volume,
          price,
          total,
        }

        if (!acc[client].total) {
          acc[client].total = {
            volume: 0,
            price: 0,
            total: 0,
          }
        }

        acc[client].total.volume += volume
        acc[client].total.price += price
        acc[client].total.total += total

        return acc
      }, {})
    },
  },
  computed: {
    totalVolume() {
      return this.commercial.reduce(
        (acc, register) => acc + Number(register.VOLUME),
        0,
      )
    },
    totalValue() {
      return this.commercial.reduce(
        (acc, register) => acc + Number(register.VALORTOTAL),
        0,
      )
    },
  },
  mounted() {
    this.convertToTable()
  },
}
</script>

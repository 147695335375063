<template>
  <div class="card">
    <div class="card-header d-flex align-items-center p-1">
      <h4 class="mb-0 ms-2 text-secondary">Avaliação Controladoria</h4>
      <div class="ms-auto"></div>
    </div>
    <LoadingData v-if="isLoadingAvaliation" />
    <div v-else>
      <ModalApproveManager
        v-if="showApproveModal"
        :companyCode="companyCode"
        :stepForm="selectedStepForm"
        @closeModal="closeModal"
      />
      <ModalRepproveManager
        v-if="showRepproveModal"
        :companyCode="companyCode"
        :stepForm="selectedStepForm"
        @closeModal="closeModal"
      />
      <ul class="nav nav-tabs nav-tabs-underline nav-justified" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            href="#tab-form-fixed-cost-manager"
            class="nav-link"
            data-bs-toggle="tab"
            role="tab"
          >
            <div class="d-flex align-items-center">
              <i
                class="ph-tag ph-lg bg-primary text-primary bg-opacity-10 rounded p-2 me-3"
              ></i>
              <div>
                <div class="fw-semibold">Custo Fixo</div>
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a
            href="#tab-form-variable-cost-manager"
            class="nav-link"
            data-bs-toggle="tab"
            role="tab"
          >
            <div class="d-flex align-items-center">
              <i
                class="ph-shopping-cart-simple bg-primary text-primary ph-lg bg-opacity-10 rounded p-2 me-3"
              ></i>
              <div>
                <div class="fw-semibold">Custo Variável</div>
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a
            href="#tab-form-personal-cost-manager"
            class="nav-link"
            data-bs-toggle="tab"
            role="tab"
          >
            <div class="d-flex align-items-center">
              <i
                class="ph-users-three bg-primary text-primary ph-lg bg-opacity-10 rounded p-2 me-3"
              ></i>
              <div>
                <div class="fw-semibold">Custos Pessoais</div>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div
          class="tab-pane active fade show"
          id="tab-form-fixed-cost-manager"
          role="tabpanel"
        >
          <div v-for="stepForm in registers" :key="stepForm.IDFORMETAPA">
            <div v-if="stepForm.CODIGO == 'FormFixedCostManager'">
              <div class="card-body">
                <h4 class="mb-0 ms-2 text-primary text-center">
                  {{ stepForm.ETAPA }}
                </h4>
              </div>
              <ReportFixedCostManager
                :form="form"
                :stepId="stepId"
                :companyCode="companyCode"
                :registers="stepForm.registers"
                :onlyView="true"
              />
              <div v-if="stepForm.avaliation">
                <div class="card-body py-0">
                  <div
                    class="alert alert-success alert-dismissible fade show"
                    v-if="stepForm.avaliation.STATUS == 1"
                  >
                    <i class="ph-check-circle me-2"></i>
                    <span class="fw-semibold">Aprovado!</span> Formulário
                    aprovado em
                    {{ dateTimeSqlToDateTimeBR(stepForm.avaliation.CRIADOEM) }}
                    por {{ stepForm.avaliation.CRIADOPOR }}.
                  </div>
                </div>
              </div>
              <div v-else-if="stepForm.lastAvaliation">
                <div class="card-body py-0">
                  <div
                    class="alert alert-danger alert-dismissible fade show"
                    v-if="stepForm.avaliation.STATUS == -1"
                  >
                    <i class="ph-check-circle me-2"></i>
                    <span class="fw-semibold">Reprovado!</span> Formulário
                    reprovado em
                    {{ dateTimeSqlToDateTimeBR(stepForm.avaliation.CRIADOEM) }}
                    por {{ stepForm.avaliation.CRIADOPOR }}. Justificativa:
                    {{ stepForm.avaliation.JUSTIFICATIVA }}
                  </div>
                </div>
              </div>
              <div v-else class="card-body py-0 text-end">
                <button
                  class="btn btn-danger ms-2"
                  @click="repproveForm(stepForm)"
                >
                  Reprovar
                </button>
                <button
                  class="btn btn-success ms-2"
                  @click="approveForm(stepForm)"
                >
                  Aprovar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="tab-form-variable-cost-manager"
          role="tabpanel"
        >
          <div v-for="stepForm in registers" :key="stepForm.IDFORMETAPA">
            <div v-if="stepForm.CODIGO == 'FormVariableCostManager'">
              <div class="card-body">
                <h4 class="mb-0 ms-2 text-primary text-center">
                  {{ stepForm.ETAPA }}
                </h4>
              </div>
              <ReportVariableCostManager
                :form="form"
                :stepId="stepId"
                :companyCode="companyCode"
                :registers="stepForm.registers"
                :onlyView="true"
              />
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="tab-form-personal-cost-manager"
          role="tabpanel"
        >
          <h2>Custos Pessoais</h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatToCurrencyBr } from '@/utils/currency'
import { dateTimeSqlToDateTimeBR } from '@/utils/date'
import { statusClassControllershipEvaluation } from '@/utils/strings'
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import ModalApproveManager from '@/components/forms/managerEvaluation/ModalApproveManager.vue'
import ModalRepproveManager from '@/components/forms/managerEvaluation/ModalRepproveManager.vue'
import ReportFixedCostManager from '@/components/forms/fixedCostManager/ReportFixedCostManager.vue'
import ReportVariableCostManager from '@/components/forms/variableCostManager/ReportVariableCostManager.vue'
import ReportPersonalCostManager from '@/components/forms/personalCostManager/ReportPersonalCostManager.vue'

export default {
  name: 'FormControllershipEvaluation',
  props: {
    stepId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
  },
  components: {
    LoadingData,
    ReportFixedCostManager,
    ReportVariableCostManager,
    ModalApproveManager,
    ModalRepproveManager,
  },
  data() {
    return {
      statusClassControllershipEvaluation,
      dateTimeSqlToDateTimeBR,
      formatToCurrencyBr,
      // Dados
      isLoadingAvaliation: false,
      avaliation: {},
      isLoadingRegisters: false,
      registers: [],
      selectedStepForm: null,
      showApproveModal: false,
      showRepproveModal: false,
    }
  },
  emits: ['updateForms'],

  methods: {
    async getRegistersManagerEvaluation() {
      try {
        this.isLoadingRegisters = true
        this.registers = await this.$store.dispatch(
          'getRegistersManagerEvaluation',
          {
            companyCode: this.companyCode,
            stepId: this.stepId,
            formId: this.form.IDFORMULARIO,
          },
        )
        this.isLoadingRegisters = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getManagerCostBudget() {
      try {
        this.isLoadingPurchasing = true
        this.purchasing = await this.$store.dispatch(
          'getPurchasingBudget',
          this.companyCode,
        )
        this.purchasingFromStepId = this.purchasing[0].IDFORMULARIOETAPA
        this.isLoadingPurchasing = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async approveForm(data) {
      this.selectedStepForm = data
      this.showApproveModal = true
    },
    async repproveForm(data) {
      this.selectedStepForm = data
      this.showRepproveModal = true
    },
    closeModal(needReload) {
      this.selectedStepForm = null
      this.showApproveModal = false
      this.showRepproveModal = false
      if (needReload) {
        this.$emit('updateForms')
      }
    },
  },

  mounted() {
    this.getRegistersManagerEvaluation()
  },
}
</script>

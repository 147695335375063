<template>
  <div v-for="form in forms" :key="form.IDFORMULARIO">
    <FormDraftInitial
      v-if="form.CODIGO == 'FormDraftInitial'"
      :companyCode="companyCode"
      :stepId="stepId"
      :form="form"
      @updateForms="reloadStep"
    />

    <FormCommercial
      v-if="form.CODIGO == 'FormCommercial'"
      :companyCode="companyCode"
      :stepId="stepId"
      :form="form"
      @updateForms="reloadStep"
    />

    <FormPurchasing
      v-if="form.CODIGO == 'FormCompras'"
      :companyCode="companyCode"
      :stepId="stepId"
      :form="form"
      @updateForms="reloadStep"
    />
    <FormLaborCost
      v-if="form.CODIGO == 'FormCustosTrabalhistas'"
      :companyCode="companyCode"
      :stepId="stepId"
      :form="form"
      @updateForms="reloadStep"
    />
    <FormControllershipEvaluation
      v-if="form.CODIGO == 'FormControllershipEvaluation'"
      :companyCode="companyCode"
      :stepId="stepId"
      :form="form"
      @updateForms="reloadStep"
    />
    <FormFixedCostManager
      v-if="form.CODIGO == 'FormFixedCostManager'"
      :companyCode="companyCode"
      :stepId="stepId"
      :form="form"
      @updateForms="reloadStep"
    />
    <FormVariableCostManager
      v-if="form.CODIGO == 'FormVariableCostManager'"
      :companyCode="companyCode"
      :stepId="stepId"
      :form="form"
      @updateForms="reloadStep"
    />
    <FormPersonalCostManager
      v-if="form.CODIGO == 'FormPersonalCostManager'"
      :companyCode="companyCode"
      :stepId="stepId"
      :form="form"
      @updateForms="reloadStep"
    />
    <FormManagerEvaluation
      v-if="form.CODIGO == 'FormManagerEvaluation'"
      :companyCode="companyCode"
      :stepId="stepId"
      :form="form"
      @updateForms="reloadStep"
    />
  </div>
</template>
<script>
import FormDraftInitial from '@/components/forms/draft/FormDraftInitial.vue'
import FormCommercial from '@/components/forms/commercial/FormCommercial.vue'
import FormPurchasing from '@/components/forms/purchasing/FormPurchasing.vue'
import FormLaborCost from '@/components/forms/laborCost/FormLaborCost.vue'
import FormControllershipEvaluation from '@/components/forms/controllershipEvaluation/FormControllershipEvaluation.vue'
import FormFixedCostManager from '@/components/forms/fixedCostManager/FormFixedCostManager.vue'
import FormVariableCostManager from '@/components/forms/variableCostManager/FormVariableCostManager.vue'
import FormPersonalCostManager from '@/components/forms/personalCostManager/FormPersonalCostManager.vue'
import FormManagerEvaluation from '@/components/forms/managerEvaluation/FormManagerEvaluation.vue'

export default {
  name: 'StepAvaliable',
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  components: {
    FormDraftInitial,
    FormCommercial,
    FormPurchasing,
    FormLaborCost,
    FormControllershipEvaluation,
    FormFixedCostManager,
    FormVariableCostManager,
    FormPersonalCostManager,
    FormManagerEvaluation,
  },
  data() {
    return {
      forms: [],
      avaliableForms: [],
      companyCode: this.$route.params.code,
      stepId: this.step.NUMETAPA,
    }
  },
  emits: ['getAvaliableStepsOnFlow'],
  methods: {
    reloadStep() {
      this.getStepForms()
      this.$emit('getAvaliableStepsOnFlow')
    },
    async getStepForms() {
      try {
        this.forms = []
        this.forms = await this.$store.dispatch('getStepForms', {
          stepId: this.step.NUMETAPA,
          companyCode: this.$route.params.code,
        })

        if (this.forms.length > 0) {
          this.avaliableForms = this.forms.filter((form) => form.STATUS === 1)
          this.avaliableForms = this.avaliableForms.map((form) => {
            return form.CODIGO
          })
        }
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.getStepForms()
  },
}
</script>

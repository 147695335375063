<template>
  <div class="">
    <ModalCreateAndEditCollaborator
      v-if="showModalCreateOrEditCollaborator"
      :collaboratorId="selectedCollaborator"
      :stepId="stepId"
      :form="form"
      :companyCode="companyCode"
      @closeModal="closeModal"
    />
    <ModalDeleteNewCollaboratorPersonalCost
      v-if="showModalDeleteNewCollaboratorPersonalCost"
      :selectedItem="selectedItem"
      @closeModal="closeModal"
    />
    <LoadingData v-if="isLoadingData" />
    <div v-else class="row">
      <div class="card-body col-12">
        <h6 class="mb-1 text-center text-primary">
          Contratações
          <span
            ><button
              class="btn btn-success btn-sm"
              @click="openModalNewCollaborator()"
            >
              Nova Contratação
            </button></span
          >
        </h6>
      </div>
      <div class="card-body" v-if="report.newColaborators.lenght === 0">
        <p class="text-center">Nenhuma contratação orçada</p>
      </div>
      <div class="table-responsive" v-else>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Função</th>
              <th>Centro de Custo</th>
              <th>Seção</th>
              <th>Mês</th>
              <th>Salário</th>
              <th>Insalub.</th>
              <th>Pericul.</th>
              <th>Adic. Noturno</th>
              <th class="text-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="colaborador in report.newColaborators"
              :key="colaborador.IDGERCPC"
            >
              <td>{{ colaborador.FUNCAO }}</td>
              <td>
                {{ colaborador.CODCCUSTO }} - {{ colaborador.CENTRODECUSTO }}
              </td>
              <td>{{ colaborador.CODSECAO }} - {{ colaborador.SECAO }}</td>
              <td>{{ colaborador.MES }}</td>
              <td>{{ formatToCurrencyBr(colaborador.SALARIO) }}</td>
              <td>{{ colaborador.INSALUBRIDADE == 1 ? 'Sim' : 'Não' }}</td>
              <td>{{ colaborador.PERICULOSIDADE == 1 ? 'Sim' : 'Não' }}</td>
              <td>{{ colaborador.ADICIONALNOTURNO == 1 ? 'Sim' : 'Não' }}</td>
              <td class="text-center p-0">
                <button
                  title="Editar"
                  class="btn btn-warning btn-sm me-2"
                  @click="editNewCollaborator(colaborador.IDGERCPC)"
                >
                  <i class="ph ph-pencil"></i>
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteNewCollaborator(colaborador)"
                >
                  <i class="ph ph-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body col-12">
        <h6 class="mb-1 mt-2 text-center text-primary">Demissões</h6>
      </div>
      <div class="card-body" v-if="report.demissions.length === 0">
        <p class="text-center">Nenhuma demissão orçada</p>
      </div>
      <div class="table-responsive" v-else>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Função</th>
              <th>Centro de Custo</th>
              <th>Seção</th>
              <th>Mês</th>
              <th>Salário</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="colaborador in report.demissions"
              :key="colaborador.IDGERCPC"
            >
              <td>{{ colaborador.FUNCAO }}</td>
              <td>
                {{ colaborador.CODCCUSTO }} - {{ colaborador.CENTRODECUSTO }}
              </td>
              <td>{{ colaborador.CODSECAO }} - {{ colaborador.SECAO }}</td>
              <td>{{ colaborador.MES }}</td>
              <td>{{ formatToCurrencyBr(colaborador.SALARIO) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body col-12">
        <h6 class="mb-1 text-center text-primary">Promoções</h6>
      </div>
      <div class="card-body" v-if="report.promotions.length === 0">
        <p class="text-center">Nenhuma promoção orçada</p>
      </div>
      <div class="table-responsive" v-else>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Função</th>
              <th>Colaborador</th>
              <th>Mês</th>
              <th>Salário</th>
              <th>Novo Salário</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="colaborador in report.promotions"
              :key="colaborador.IDGERCPM"
            >
              <td>{{ colaborador.CARGO }}</td>
              <td>
                {{ colaborador.NOME }}
              </td>
              <td>{{ colaborador.MES }}</td>
              <td>{{ formatToCurrencyBr(colaborador.SALARIO) }}</td>
              <td>{{ formatToCurrencyBr(colaborador.NOVOSALARIO) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body col-12">
        <h6 class="mb-1 text-center text-primary">Manter</h6>
      </div>
      <div class="card-body" v-if="report.keepColaborators.length === 0">
        <p class="text-center">
          Nenhum colaborador foi mantido na mesma condição.
        </p>
      </div>
      <div class="table-responsive" v-else>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Função</th>
              <th>Centro de Custo</th>

              <th>Salário</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="colaborador in report.keepColaborators"
              :key="colaborador.IDGERCPM"
            >
              <td>{{ colaborador.CARGO }}</td>
              <td>
                {{ colaborador.NOME }}
              </td>
              <td>{{ formatToCurrencyBr(colaborador.SALARIO) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import ModalCreateAndEditCollaborator from '@/components/forms/personalCostManager/ModalCreateAndEditCollaborator.vue'
import ModalDeleteNewCollaboratorPersonalCost from '@/components/forms/personalCostManager/ModalDeleteNewCollaboratorPersonalCost.vue'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'ReportCollaborador',
  props: {
    stepId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
    report: {
      type: Object,
      required: true,
    },
  },
  components: {
    LoadingData,
    ModalCreateAndEditCollaborator,
    ModalDeleteNewCollaboratorPersonalCost,
  },
  data() {
    return {
      formatToCurrencyBr,
      selectedCollaborator: null,
      selectedItem: null,
      showModalCreateOrEditCollaborator: false,
      showModalDeleteNewCollaboratorPersonalCost: false,
      isLoadingData: false,
      months: [
        { value: '01', name: 'Janeiro' },
        { value: '02', name: 'Fevereiro' },
        { value: '03', name: 'Março' },
        { value: '04', name: 'Abril' },
        { value: '05', name: 'Maio' },
        { value: '06', name: 'Junho' },
        { value: '07', name: 'Julho' },
        { value: '08', name: 'Agosto' },
        { value: '09', name: 'Setembro' },
        { value: '10', name: 'Outubro' },
        { value: '11', name: 'Novembro' },
        { value: '12', name: 'Dezembro' },
      ],
    }
  },
  emits: ['reloadData'],

  methods: {
    openModalNewCollaborator() {
      this.selectedCollaborator = null
      this.showModalCreateOrEditCollaborator = true
    },
    deleteNewCollaborator(item) {
      this.selectedItem = item
      this.showModalDeleteNewCollaboratorPersonalCost = true
    },
    editNewCollaborator(id) {
      this.selectedCollaborator = id
      this.showModalCreateOrEditCollaborator = true
    },
    closeModal(needReload) {
      this.showModalCreateOrEditCollaborator = false
      if (needReload) {
        this.$emit('reloadData')
      }
    },
  },
}
</script>

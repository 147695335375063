<template>
  <div v-if="isLoadingCostCenter">
    <LoadingData />
  </div>
  <div class="card-body">
    <form @submit.prevent="saveFixedCostForm">
      <div>
        <UploadExcelFile
          v-if="showModalUploadExcel"
          @closeModal="closeModal"
          @importData="importData"
        ></UploadExcelFile>
        <DownloadExcelFile
          v-if="showDownloadExcelFile"
          @finish="showDownloadExcelFile = false"
          :fieldsMap="fieldsMap"
          :link="form.LINK"
          :name="'modelo_preenchimento_custos_gestor_pessoal'"
        />
        <ModalViewDraft v-if="showModalViewDraft" @closeModal="closeModal" />
        <UploadAttachment
          v-if="showModalUploadAttachment"
          :companyCode="companyCode"
          :form="form"
          @closeModal="closeModal"
        ></UploadAttachment>
        <ModalAttachment
          v-if="showModalAttachments"
          :companyCode="companyCode"
          :formStepId="form.IDFORMETAPA"
          @closeModal="closeModal"
        ></ModalAttachment>
      </div>
      <div class="row" v-if="disableEdit">
        <div class="col-12">
          <p class="text-center m-5">Formulário finalizado!</p>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12 col-md-4 mb-3">
          <label class="form-label">Natureza:</label>
          <select
            class="form-select"
            placeholder="Selecione uma opção"
            :class="{
              'is-invalid': v$.formItem.item.$errors.length,
            }"
            v-model="v$.formItem.item.$model"
          >
            <option value="" disabled>Selecione uma opção</option>
            <option
              v-for="NAT in COSTCENTERS"
              :key="NAT.CODNATUREZA"
              :value="NAT"
            >
              {{ NAT.CODNATUREZA }} - {{ NAT.NATUREZA }}
            </option>
          </select>

          <span
            v-if="v$.formItem.item.required.$invalid"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <label class="form-label">Competência:</label>
          <select
            class="form-select"
            placeholder="Selecione uma opção"
            :class="{
              'is-invalid': v$.formItem.competencia.$errors.length,
            }"
            v-model="v$.formItem.competencia.$model"
          >
            <option
              v-for="month in months"
              :key="month.value"
              :value="month.value"
            >
              {{ month.name }}
            </option>
          </select>
          <span
            v-if="v$.formItem.competencia.required.$invalid"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>

        <div class="col-12 col-md-4 mb-3">
          <label class="form-label">Custo:</label>
          <input
            type="number"
            step="0.01"
            class="form-control"
            placeholder="Valor"
            :class="{
              'is-invalid': v$.formItem.valor.$errors.length,
            }"
            v-model="v$.formItem.valor.$model"
          />
          <span
            v-if="v$.formItem.valor.required.$invalid"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="col-12 mb-1" v-if="!disableEdit">
          <div class="d-flex justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-yellow me-2"
                @click="showModalViewDraft = true"
              >
                <i class="ph-calculator me-2"></i> Draft
              </button>
              <div class="btn-group me-2" v-if="!disableEdit">
                <button type="button" class="btn btn-success" disabled>
                  <i class="ph ph-file-xls me-2"></i>Planilha
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  title="Download do modelo de planilha para importação"
                  @click="showDownloadExcelFile = true"
                >
                  <i class="ph-download"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  title="Realiza o upload da planilha preenchida"
                  @click="showModalUploadExcel = true"
                >
                  <i class="ph-upload"></i>
                </button>
              </div>
              <div class="btn-group me-2">
                <button type="button" class="btn btn-secondary" disabled>
                  <i class="ph ph-paperclip-horizontal me-2"></i>Anexos
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  title="Anexar um novo arquivo"
                  @click="showModalUploadAttachment = true"
                >
                  <i class="ph-paperclip"></i>
                </button>
                <button
                  v-if="!disableEdit"
                  type="button"
                  class="btn btn-secondary"
                  title="Consultar anexos"
                  @click="showModalAttachments = true"
                >
                  <i class="ph-eye"></i>
                </button>
              </div>
            </div>

            <button
              type="submit"
              class="btn btn-primary ms-2"
              :disabled="v$.$invalid || disableButton"
            >
              <i class="ph-floppy-disk me-2"></i> Cadastrar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'

import LoadingData from '@/components/commons/globals/LoadingData.vue'
import UploadExcelFile from '@/components/forms/UploadExcelFile.vue'
import DownloadExcelFile from '@/components/forms/DownloadExcelFile.vue'
import ModalViewDraft from '@/components/forms/draft/ModalViewDraft.vue'
import UploadAttachment from '@/components/forms/UploadAttachment.vue'
import ModalAttachment from '@/components/forms/ModalAttachment.vue'
import { validateHeader, atributeValues } from '@/utils/upload'
import { formatToCurrencyBr } from '@/utils/currency'

export default {
  name: 'FormFixedCostManager',
  props: {
    stepId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    UploadExcelFile,
    DownloadExcelFile,
    LoadingData,
    ModalViewDraft,
    UploadAttachment,
    ModalAttachment,
  },
  data() {
    return {
      pageTitle: 'Custos Fixos',
      formatToCurrencyBr,
      // Modais
      showModalUploadExcel: false,
      showDownloadExcelFile: false,
      showModalViewDraft: false,
      showModalUploadAttachment: false,
      showModalAttachments: false,
      // Loading
      isLoadingCostCenter: true,
      isLoadingRegisters: true,
      disableButton: false,
      fieldsMap: {
        centroDeCustoCodigo: 'Código Centro de Custo',
        centroDeCustoNome: 'Centro de Custo',
        naturezaCodigo: 'Código Natureza',
        naturezaNome: 'Natureza',
        competencia: 'Competência',
        valor: 'Valor',
      },
      formItem: {
        item: null,
        centroCustoCodigo: '',
        centroCustoNome: '',
        naturezaCodigo: '',
        naturezaNome: '',
        competencia: '',
        valor: '',
      },
      formItems: [],
      registers: [],
      fixedCosts: [],
      COSTCENTERS: [],
      NATURES: [],
      months: [
        { value: '01', name: 'Janeiro' },
        { value: '02', name: 'Fevereiro' },
        { value: '03', name: 'Março' },
        { value: '04', name: 'Abril' },
        { value: '05', name: 'Maio' },
        { value: '06', name: 'Junho' },
        { value: '07', name: 'Julho' },
        { value: '08', name: 'Agosto' },
        { value: '09', name: 'Setembro' },
        { value: '10', name: 'Outubro' },
        { value: '11', name: 'Novembro' },
        { value: '12', name: 'Dezembro' },
      ],
    }
  },
  emits: ['reloadData'],
  computed: {
    disableEdit() {
      return this.form.STATUS_FORMULARIO_ETAPA === 1
    },
  },
  methods: {
    finishProcess() {
      this.$emit('reloadData')
    },
    importData(data) {
      const res = validateHeader(data, this.fieldsMap)
      if (!res.status) {
        this.$root.$refs.notification.error(res.message)
        return false
      }
      const values = atributeValues(data, this.fieldsMap)
      if (!values.status) {
        this.$root.$refs.notification.error(values.message)
        return false
      }
      this.formItems = values.data
      this.save()
      this.showModalUploadExcel = false
    },
    closeModal() {
      this.showModalUploadExcel = false
      this.showDownloadExcelFile = false
      this.showModalViewDraft = false
      this.showModalUploadAttachment = false
      this.showModalAttachments = false
    },
    downloadTemplateDraftXls() {
      window.open(this.linkTemplateDraftXls, '_blank')
    },
    async getFixedCostsByUnit() {
      try {
        this.isLoadingCostCenter = true
        this.COSTCENTERS = await this.$store.dispatch(
          'getPersonalCostsNaturesByUnit',
          this.companyCode,
        )
        this.isLoadingCostCenter = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getFixedCostsRegisteredsByUnit() {
      try {
        this.isLoadingRegisters = true
        this.registers = await this.$store.dispatch(
          'getFixedCostsRegisteredsByUnit',
          {
            companyCode: this.companyCode,
            stepId: this.stepId,
            formId: this.form.IDFORMULARIO,
            formStepId: this.form.IDFORMETAPA,
          },
        )
        this.isLoadingRegisters = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async saveFixedCostForm() {
      if (!this.v$.$invalid) {
        this.formItem.centroDeCustoCodigo = this.formItem.item.CODCCUSTO
        this.formItem.centroDeCustoNome = this.formItem.item.CENTRODECUSTO
        this.formItem.naturezaCodigo = this.formItem.item.CODNATUREZA
        this.formItem.naturezaNome = this.formItem.item.NATUREZA
        this.formItems = [this.formItem]
        this.save()
      }
    },
    async save() {
      try {
        this.disableButton = true
        const data = await this.$store.dispatch('savePersonalCostManager', {
          form: {
            stepId: this.stepId,
            formId: this.form.IDFORMULARIO,
            formStepId: this.form.IDFORMETAPA,
            formItems: this.formItems,
          },
          companyCode: this.companyCode,
        })
        this.$root.$refs.notification.success(data.message)
        this.formItem = {
          item: null,
          centroCustoCodigo: '',
          centroCustoNome: '',
          naturezaCodigo: '',
          naturezaNome: '',
          competencia: '',
          custoUnitario: '',
        }
        this.finishProcess()
        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  validations() {
    return {
      formItem: {
        competencia: {
          required,
        },
        item: {
          required,
        },
        valor: {
          required,
        },
      },
    }
  },
  mounted() {
    this.getFixedCostsRegisteredsByUnit()
    this.getFixedCostsByUnit()
  },
}
</script>

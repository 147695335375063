<template>
  <div id="modalUpdate" class="modal fade" tabindex="-1">
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Alterar Valor do Registro</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="updateItem">
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-12 mb-3">
                <label class="form-label">Valor:</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  placeholder="Valor"
                  :class="{
                    'is-invalid': v$.item.CUSTOUNITARIO.$errors.length,
                  }"
                  v-model="v$.item.CUSTOUNITARIO.$model"
                />
                <span
                  v-if="v$.item.CUSTOUNITARIO.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pt-0">
            <button
              type="button"
              class="btn btn-link text-warning"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-warning"
              title="Confirmar"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>Alterar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'ModalEditPurchasing',
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: {
        IDCOMPRAS: this.selectedItem.IDCOMPRAS,
        CUSTOUNITARIO: this.selectedItem.CUSTOUNITARIO,
      },
      modalSize: 'modal-sm',
      disableButton: false,
    }
  },
  emits: ['closeModal'],
  methods: {
    async updateItem() {
      try {
        this.disableButton = true
        const data = await this.$store.dispatch('updateFormPurchasing', {
          id: this.selectedItem.IDCOMPRAS,
          companyCode: this.selectedItem.CODUNIDADE,
          form: this.item,
        })
        this.$root.$refs.notification.success(data.message)
        hideModal('modalUpdate')
        this.$emit('closeModal', true)
      } catch (error) {
        console.error(error)
        this.disableButton = false
      }
    },
  },
  validations() {
    return {
      item: {
        CUSTOUNITARIO: {
          required,
        },
      },
    }
  },
  mounted() {
    showModal('modalUpdate')
  },
}
</script>

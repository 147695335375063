<template>
  <div id="modalUpdate" class="modal fade" tabindex="-1">
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }} Novo Colaborador</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <LoadingData v-if="isLoadingParams || isLoadingNewCollaborator" />
        <form v-else @submit.prevent="save">
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-md-6 mb-3">
                <label class="form-label">Departamento:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid': v$.newCollaborator.section.$errors.length,
                  }"
                  v-model="v$.newCollaborator.section.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>
                  <option
                    v-for="section in params.sections"
                    :key="section.CODIGO"
                    :value="section"
                  >
                    {{ section.CODIGO }} - {{ section.DESCRICAO }}
                  </option>
                </select>

                <span
                  v-if="v$.newCollaborator.section.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label class="form-label">Centro de Custo:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid': v$.newCollaborator.centerCost.$errors.length,
                  }"
                  v-model="v$.newCollaborator.centerCost.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>
                  <option
                    v-for="CC in params.costCenter"
                    :key="CC.CODCCUSTO"
                    :value="CC"
                  >
                    {{ CC.CODCCUSTO }} - {{ CC.CENTRODECUSTO }}
                  </option>
                </select>

                <span
                  v-if="v$.newCollaborator.centerCost.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label class="form-label">Cargo:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid': v$.newCollaborator.position.$errors.length,
                  }"
                  v-model="v$.newCollaborator.position.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>
                  <option
                    value='{"CODIGO": "NOVO CARGO", "CARGO" : "CARGO NÃO REGISTRADO"}'
                  >
                    000 - CARGO NÃO REGISTRADO
                  </option>
                  <option
                    v-for="position in params.positions"
                    :key="position.CODIGO"
                    :value="position"
                  >
                    {{ position.CODIGO }} - {{ position.CARGO }}
                  </option>
                </select>

                <span
                  v-if="v$.newCollaborator.position.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-3 mb-3">
                <label class="form-label">Competência:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid': v$.newCollaborator.month.$errors.length,
                  }"
                  v-model="v$.newCollaborator.month.$model"
                >
                  <option
                    v-for="month in months"
                    :key="month.value"
                    :value="month.value"
                  >
                    {{ month.name }}
                  </option>
                </select>
                <span
                  v-if="v$.newCollaborator.month.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>

              <div class="col-12 col-md-3 mb-3">
                <label class="form-label">Salário:</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  placeholder="Salário"
                  :class="{
                    'is-invalid': v$.newCollaborator.salary.$errors.length,
                  }"
                  v-model="v$.newCollaborator.salary.$model"
                />
                <span
                  v-if="v$.newCollaborator.salary.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-4 mb-3">
                <label class="form-label">Periculosidade:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid':
                      v$.newCollaborator.periculosidade.$errors.length,
                  }"
                  v-model="v$.newCollaborator.periculosidade.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
                <span
                  v-if="v$.newCollaborator.periculosidade.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-4 mb-3">
                <label class="form-label">Adicional Noturno:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid':
                      v$.newCollaborator.adicionalNoturno.$errors.length,
                  }"
                  v-model="v$.newCollaborator.adicionalNoturno.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
                <span
                  v-if="v$.newCollaborator.adicionalNoturno.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12 col-md-4 mb-3">
                <label class="form-label">Insalubridade:</label>
                <select
                  class="form-select"
                  placeholder="Selecione uma opção"
                  :class="{
                    'is-invalid':
                      v$.newCollaborator.insalubridade.$errors.length,
                  }"
                  v-model="v$.newCollaborator.insalubridade.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
                <span
                  v-if="v$.newCollaborator.insalubridade.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pt-0">
            <button
              type="button"
              class="btn btn-link text-info"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-info"
              :title="title"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>{{ title }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { showModal, hideModal } from '@/utils/modal'
import { formatToCurrencyBr } from '@/utils/currency'
import LoadingData from '@/components/commons/globals/LoadingData.vue'

export default {
  name: 'ModalCreateAndEditCollaborator',
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    LoadingData,
  },
  props: {
    collaboratorId: {
      type: Number,
      required: false,
    },
    stepId: {
      type: Number,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: 'Cadastrar',
      formatToCurrencyBr,
      // Loading
      isLoadingParams: true,
      isLoadingNewCollaborator: true,
      disableButton: false,
      params: {
        sections: [],
        costCenter: [],
        positions: [],
      },
      newCollaborator: {
        position: '',
        centerCost: '',
        section: '',
        salary: '',
        month: '',
        periculosidade: '',
        adicionalNoturno: '',
        insalubridade: '',
      },

      months: [
        { value: 1, name: 'Janeiro' },
        { value: 2, name: 'Fevereiro' },
        { value: 3, name: 'Março' },
        { value: 4, name: 'Abril' },
        { value: 5, name: 'Maio' },
        { value: 6, name: 'Junho' },
        { value: 7, name: 'Julho' },
        { value: 8, name: 'Agosto' },
        { value: 9, name: 'Setembro' },
        { value: 10, name: 'Outubro' },
        { value: 11, name: 'Novembro' },
        { value: 12, name: 'Dezembro' },
      ],

      modalSize: 'modal-lg',
    }
  },
  emits: ['closeModal'],
  methods: {
    async getPersonalCostsParams() {
      try {
        this.isLoadingParams = true
        this.params = await this.$store.dispatch(
          'getPersonalCostsParams',
          this.companyCode,
        )
        this.isLoadingParams = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getNewCollaboratorData() {
      try {
        this.isLoadingNewCollaborator = true
        const newColaboratorRaw = await this.$store.dispatch(
          'getPersonalCostsNewCollaborator',
          {
            companyCode: this.companyCode,
            collaboratorId: this.collaboratorId,
          },
        )
        this.newCollaborator = {
          position: {
            CODIGO: newColaboratorRaw.CODFUNCAO,
            CARGO: newColaboratorRaw.FUNCAO,
          },
          centerCost: {
            CODCCUSTO: newColaboratorRaw.CODCCUSTO,
            CENTRODECUSTO: newColaboratorRaw.CENTRODECUSTO,
          },
          section: {
            CODIGO: newColaboratorRaw.CODSECAO,
            DESCRICAO: newColaboratorRaw.SECAO,
          },
          salary: newColaboratorRaw.SALARIO,
          month: newColaboratorRaw.MES,
          periculosidade: newColaboratorRaw.PERICULOSIDADE,
          adicionalNoturno: newColaboratorRaw.ADICIONALNOTURNO,
          insalubridade: newColaboratorRaw.INSALUBRIDADE,
        }
        this.isLoadingNewCollaborator = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    closeModal(needReload) {
      hideModal('modalUpdate')
      this.$emit('closeModal', needReload)
    },
    async save() {
      try {
        this.disableButton = true
        let data = null
        if (this.collaboratorId) {
          data = await this.$store.dispatch(
            'updatePersonalCostsNewCollaborator',
            {
              form: {
                stepId: this.stepId,
                formId: this.form.IDFORMULARIO,
                formStepId: this.form.IDFORMETAPA,
                newCollaborator: this.newCollaborator,
              },
              companyCode: this.companyCode,
              collaboratorId: this.collaboratorId,
            },
          )
        } else {
          data = await this.$store.dispatch(
            'savePersonalCostsNewCollaborator',
            {
              form: {
                stepId: this.stepId,
                formId: this.form.IDFORMULARIO,
                formStepId: this.form.IDFORMETAPA,
                newCollaborator: this.newCollaborator,
              },
              companyCode: this.companyCode,
            },
          )
        }
        this.$root.$refs.notification.success(data.message)
        this.newCollaborator = {
          position: '',
          centerCost: '',
          section: '',
          salary: '',
          month: '',
          periculosidade: '',
          adicionalNoturno: '',
          insalubridade: '',
        }
        this.closeModal(true)
        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  validations() {
    return {
      newCollaborator: {
        position: {
          required,
        },
        centerCost: {
          required,
        },
        section: {
          required,
        },
        salary: {
          required,
        },
        month: {
          required,
        },
        periculosidade: {
          required,
        },
        adicionalNoturno: {
          required,
        },
        insalubridade: {
          required,
        },
      },
    }
  },
  mounted() {
    this.getPersonalCostsParams()
    if (this.collaboratorId) {
      this.title = 'Alterar'
      this.getNewCollaboratorData()
    } else {
      this.isLoadingNewCollaborator = false
    }
    showModal('modalUpdate')
  },
}
</script>
